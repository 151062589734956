import { Controller } from "@hotwired/stimulus"
import styles from "./../utils/styles"

import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'

cytoscape.use( dagre )
cytoscape.warnings(false)

export default class extends Controller {
  static targets = [ "holder" ]
  static values = {
    cyJson: Object,
  }

  disconnect() {
    // document.removeEventListener("turbo:load", this.initMap())
    // localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(cy.json()));
    // window.cy = null
  }

  connect() {
    console.log('snap connected')
    // document.addEventListener("turbo:load", this.initMap())
    this.initMap()
  }

  initMap() {
    const controller = this

    var cy = window.cy = cytoscape({

      container: controller.holderTarget,
      boxSelectionEnabled: false,
      // wheelSensitivity: 0.1,
      layout: {
        name: 'dagre',
        rankDir: 'TB',
        edgeSep: 20,
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true,
      },
      ready: (e) => {
        const cy = e.cy
        cy.json(this.cyJsonValue)
        cy.fit()
      }
    });
  }
}

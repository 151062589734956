import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import consumer from "./../channels/consumer"
import styles from "./../utils/styles"

import cytoscape from 'cytoscape'
import dagre from 'cytoscape-dagre'

cytoscape.use( dagre )
cytoscape.warnings(false)

export default class extends Controller {
  static targets = [ "holder" ]
  static values = {
    items: Array,
    fit: String,
    shuffle: String,
    cid: String,
    warninigs: Boolean,
    savePng: String,
    savePdf: String,
  }

  static classes = [ "hover" ]

  disconnect() {
    window.cy = null
    document.removeEventListener("turbo:load", this.initMap())
    const cy = this.holderTarget._cyreg.cy
    localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(cy.json()));
  }

  connect() {
    console.log('bigbic connected')
    console.log(this.itemsValue)
    document.addEventListener("turbo:load", this.initMap())

    const thisRef = this
    consumer.subscriptions.create({ channel: "CyChannel", space_id: this.cidValue }, {
      received(data) {

        // console.log('received')
        // const cy = cytoscape({container: thisRef.holderTarget})

        const cy = thisRef.holderTarget._cyreg.cy
        const savedJson = cy.json()

        const items = JSON.parse(data.nodes)


        // console.log('items', items)
        // console.log('savedJson', savedJson)

        if (savedJson) {

          const currentElements = savedJson.elements.nodes

          // console.log('currentElements')

          if (currentElements) {
            const newItems = []
            items.forEach((item) => {
              var result = currentElements.find(obj => {
                return obj.data.id === item.data.id
              })
              if (result) {
                // console.log('item', item.data.parent)
                // console.log('result', result.data.parent)
                const merged = {...result, ...item}
                merged.data = item.data
                // console.log('merged', merged.data.parent)
                newItems.push(merged)
              } else {
                newItems.push(item)
              }
            });

            // savedJson.elements.edges.forEach((item) => {
            //   newItems.push(item)
            // })

            // console.log('newItems')
            // console.log(newItems)
            // console.log('currentElements')
            // console.log(currentElements)
            // console.log('mergedItems')
            // console.log(mergedItems)

            cy.json({ elements: []})
            cy.json({ elements: newItems})

            // console.log('cy.json()', cy.json())
          }
          
          thisRef.saveImage(cy, 'received')
        }
      }
    })
  }

  initMap() {
    // console.log('hello', this.itemsValue)
    const controller = this
    
    var cy = window.cy = cytoscape({

      container: controller.holderTarget,
      boxSelectionEnabled: false,
      // wheelSensitivity: 0.1,
      layout: {
        name: 'dagre',
        // name: 'klay',
        rankDir: 'TB',
        // columns: 2,
        // rankSep: 20,
        edgeSep: 20,
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true,
        // edgeSep: 10,
        // nodeDimensionsIncludeLabels: true,
        // animate: true,
        // minLen: function( e ){ 
        //   // console.log('edge', e.data('min_len'))
        //   return e.data('min_len')
        // }
        // edgeWeight: function( edge ){ return 0 }
      },

      style: styles,

      elements: controller.itemsValue,
      ready: (e) => {
        // console.log(`ready to panX-${this.element.id}`)
        const cy = e.cy

        const savedJson = JSON.parse(localStorage.getItem(`${this.element.id}-cy`));

        // console.log('savedJson', savedJson)

        if (savedJson) {

          const currentElements = savedJson.elements.nodes

          if (currentElements) {
            const newItems = this.itemsValue.map((item) => {
              var result = currentElements.find(obj => {
                return obj.data.id === item.data.id
              })
              if (result) {
                const merged = {...result, ...item}
                const unselected = {...merged, ...{selected: false}}
                return unselected
              } else {
                return {...item, ...{selected: false}}
              }
            });

            const merged = Object.assign(savedJson, { elements: newItems } )
            const styled = Object.assign(merged, { style: styles } )

            // console.log('stylesz', styles)
            // console.log('merged', merged)
            // console.log('styled', styled)
            cy.json({elements: []})
            cy.json(styled)
            this.saveState()
          }
          
          // this.saveImage(cy, 'ready')
        }
      }
    });

    const savePngLink = document.querySelector(`#${this.savePngValue}`)

    if (savePngLink) {
      savePngLink.addEventListener('click', (e) => {
        e.preventDefault()
        console.log('нямням')
        this.postImage(cy, 'gopng')
        // cy.fit()
      })
    }
    
    const savePdfLink = document.querySelector(`#${this.savePdfValue}`)

    if (savePdfLink) {
      savePdfLink.addEventListener('click', (e) => {
        e.preventDefault()
        console.log('нямням pdf')
        this.postImage(cy, 'gopdf')
        // cy.fit()
      })
    }

    const fitLink = document.querySelector(`#${this.fitValue}`)
    if (fitLink) {
      fitLink.addEventListener('click', (e) => {
        e.preventDefault()
        // console.log('fitting')
        cy.fit()
      })
    }

    const shuffleLink = document.querySelector(`#${this.shuffleValue}`)
    if (shuffleLink) {
      shuffleLink.addEventListener('click', (e) => {
        e.preventDefault()
        cy.layout({ name: 'dagre' }).run()
        localStorage.removeItem(`${this.element.id}-cy`);
        this.saveImage(cy, 'dagre')
      })
    }

    // cy.nodes().renderHTMLNodes()

    // cy.nodes().on('onetap', (e) => {
    //   var clickedNode = e.target;
    //   const data = clickedNode.data()
    //   console.log("yaya", data)
    //   if (data.click != false) {
    //     // const url = "/themes/" + data.theme_id + "/items/" + data.id
    //     // Turbo.visit(data.url)
    //   }
    // });
    

    cy.on('zoom', (e) => {
      this.saveState()
      // this.saveImage(cy)
    })

    cy.on('pan', (e) => {
      this.saveState()
      // this.saveImage(cy)
    })

    let timeout = null;
    cy.on('dragfree', (e) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        // console.log('saving image', cy.pan())
        
        // console.log('dragfree')
        this.saveState()
        this.saveImage(cy)
      }, 150);
    })
    const clickEvent = (event) => {
      var evtTarget = event.target;

      if( evtTarget === cy ){
        // console.log('tap on background');
        // document.querySelector('#nodes').innerHTML = ''
        Turbo.visit('/pictures', { frame: 'nodes' })
      } else {
        // console.log('tap on some element');
        const clickedNode = event.target;
        const data = clickedNode.data()

        // console.log('clickedNode', clickedNode)
        // this.saveState()
        // this.saveImage(cy)

        if (data.path) {
          const bottom = document.querySelector('#nodes').classList.add('h-1/2')

          let frame
          if (data.frame) {
            frame = data.frame
          } else {
            frame = 'nodes'
          }
          console.log('frame', frame)
          Turbo.visit(`${data.path}?scroll=yesbaby`, { frame: frame })
        }
      }      
    }

    cy.on('click', clickEvent);
  }

  saveState(){
    const cy = this.holderTarget._cyreg.cy
    console.log('save-state')
    localStorage.setItem(`${this.element.id}-cy`, JSON.stringify(cy.json()));
  }

  saveImage(cy, from = ''){
    // console.log('myaouw ', from)
  }

  saveImageTm(cy, from = ''){
    // console.log('called saveImage from', from)
    window.imgtimeout = null;
    clearTimeout(window.imgtimeout)
    window.imgtimeout = setTimeout(() => {
      // console.log('saving image', cy.pan())
      
      this.postImage(cy, 'saveImage')
    }, 150);
  }

  async postImage(cy, from = ''){
    if (cy.nodes().length > 0) {

      // const elements = cy.json().elements

      // const newItems = elements.nodes.map((item) => {
      //   return {...item, ...{selected: false}}
      // });

      // if (elements.edges) {
      //   elements.edges.forEach((item) => {
      //     newItems.push(item)
      //   });
      // }

      // // console.log('from image', newItems)
      // cy.json({ elements: [] })
      // cy.json({ elements: newItems })

      // var png64 = cy.png( { full: true, bg: '#ffffff', scale: 4, output: 'base64' })

      const pngBlob = await cy.png({
        full: true, bg: '#ffffff', scale: 2,
        output: 'blob-promise',
      });

      // const fileName = 'myfile.png';
      // const downloadLink = document.createElement('a');
      // downloadLink.href = URL.createObjectURL(pngBlob);
      // downloadLink.download = fileName;
      // downloadLink.click();

      // put the png data in an img tag
      // document.querySelector('#png-eg').setAttribute('src', png64)
      let formData = new FormData();
      formData.append("image", pngBlob);
  
      const response = await post('/pictures/image', {
        responseKind: "turbo-stream",
        body: formData
      });

      if (response.ok) {
        if (from == 'gopng') {
          const url = document.querySelector(`#${this.savePngValue}`).getAttribute('href')
          console.log('going png', url)
          // Turbo.visit(url)
          window.open(url, '_blank');
        } else if (from == 'gopdf') {
          const url = document.querySelector(`#${this.savePdfValue}`).getAttribute('href')
          console.log('going pdf', url)
          // Turbo.visit(url)
          window.open(url, '_blank');
        }
        console.log('saved')
      } else {
        console.log('error')
      }
    }
  }
}
